export const FETCH_CART = "FETCH_CART";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_COURSE_TO_CART = "ADD_COURSE_TO_CART";
export const REMOVE_COURSE_FROM_CART = "REMOVE_COURSE_FROM_CART";
export const CHANGE_COUNT_OF_COURSE = "CHANGE_COUNT_OF_COURSE";
export const ADD_COURSE_ONLINE_TO_CART = "ADD_COURSE_ONLINE_TO_CART";
export const REMOVE_COURSE_ONLINE_FROM_CART = "REMOVE_COURSE_ONLINE_FROM_CART";
export const FETCH_LOCAL_BILL = "FETCH_LOCAL_BILL";
export const REMOVE_COURSE = "REMOVE_COURSE";
export const CHECKOUT_INFO = "CHECKOUT_INFO";

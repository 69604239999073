import axios from "axios";
import _, { isEmpty } from "lodash";
import { getDetailScheduleCourseOnlineAction } from "core/redux/actions/scheduleCourseOnlineAction";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SingleCourseMedia from "../components/singleCourseMedia";
import Loading from "share/loading";
import { useTranslation } from "react-i18next";
import useSiteTitle from "core/hooks/useSiteTitle";
import { addCourseOnlineToCart } from "core/redux/actions/cartAction";
import bannerSingleCourseMedia from "assets/images/source/course-media/single-course-media.jpg";
function Tower1() {
  const { t } = useTranslation("common");
  useSiteTitle(t("tower1"));

  const [userData, setUserData] = useState({});
  const [pharse, setPharse] = useState("");
  const [detailCourseOnline, setDetailCourseOnline] = useState({});
  const { detailScheduleCourseOnline } = useSelector(
    (state) => state.scheduleCourseOnline
  );
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // check login -> check user is in the student list
    let checkPermission = "";
    if (userInfo) {
      if (detailScheduleCourseOnline?.studentList?.length > 0) {
        checkPermission = detailScheduleCourseOnline.studentList.find(
          (ele) => ele._id === userInfo._id
        );
      }
    }
    if (isEmpty(checkPermission)) {
      setUserData();
    } else {
      setUserData(checkPermission);
    }
    if (!isEmpty(detailScheduleCourseOnline)) {
      setDetailCourseOnline(detailScheduleCourseOnline.courseOnline);
    }
  }, [userInfo, detailScheduleCourseOnline]);
  // Fetch Table Data
  const loadData = () => {
    dispatch(getDetailScheduleCourseOnlineAction("67208475ee94f823309ea587"));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRegister = async (e, pharse) => {
    e.preventDefault();
    if (!token) {
      navigate("/dang-nhap", { state: { from: location } });
    } else {
      let data = {
        schedule_id: detailScheduleCourseOnline._id,
        course_id: detailCourseOnline._id,
        course_name: detailCourseOnline.name,
        permission: pharse.permission,
        title: pharse.title,
        pharse: pharse.pharse,
        price: pharse.price,
        type: "courseOnline",
        img: bannerSingleCourseMedia,
        checkout: false,
        count: 1,
      };
      dispatch(addCourseOnlineToCart(data));
      // handleSendData(pharse);
    }
  };

  const handleSendData = async (pharse) => {
    const data = _.cloneDeep(userInfo);
    data["tags"] = ["register-tower1", pharse];
    data["import_by_tag_name"] = true;

    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data,
    })
      .then((response) => {
        setPharse(pharse);
        // setOpenModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRedirect = (pharseIndex, videoIndex) => {
    navigate("/chuong-trinh-huan-luyen/media/video-streaming/_id=", {
      state: {
        from: location,
        pharseIndex: pharseIndex,
        videoIndex: videoIndex,
        siteTitle: "tower1",
      },
    });
  };
  const handleRedirectZalo = () => {
    window.open("https://zalo.me/0906880917", "_blank");
  };

  return detailCourseOnline &&
    detailCourseOnline.lesson &&
    detailCourseOnline.lesson.length > 0 ? (
    <>
      <SingleCourseMedia
        name={detailCourseOnline.name}
        handleRegister={(e, pharse) => handleRegister(e, pharse)}
        numberSudentRate={168}
        description={detailCourseOnline.description}
        willLearn={[
          {
            title: "Thành Tích",
            content:
              "Hiểu rõ những yếu tố cần thiết để đạt được thành tích đỉnh cao và cách tạo ra mục đích, ý nghĩa cho cuộc sống của bạn.",
          },
          {
            title: "Bí Mật của Ba Tâm Trí",
            content:
              "Tìm hiểu về ba phần quan trọng của tâm trí và cách chúng tương tác để ảnh hưởng đến quyết định và hành vi của bạn.",
          },
          {
            title: "Sức Mạnh của Trí Tưởng Tượng",
            content:
              "Khám phá cách sử dụng trí tưởng tượng để định hình tương lai và hiện thực hóa các mục tiêu của bạn.",
          },
          {
            title: "Mô Hình Mắt",
            content:
              "Hiểu về mô hình mắt và cách nó phản ánh trạng thái tinh thần và cảm xúc của bạn.",
          },
          {
            title: "Kiểm Soát Cảm Xúc",
            content:
              "Học cách kiểm soát và quản lý cảm xúc để duy trì trạng thái tinh thần tích cực và ổn định.",
          },
          {
            title: "Mối Quan Hệ",
            content:
              "Khám phá cấu trúc của các mối quan hệ trong cuộc sống và học cách xây dựng, duy trì mối quan hệ lành mạnh và ý nghĩa.",
          },
        ]}
        forWho={[
          "Nhân viên văn phòng",
          "Nhà lãnh đạo, quản lý",
          "Học sinh, sinh viên muốn thành công",
          "Nhà tư vấn, Chuyên gia tâm lý",
          "Người muốn phát triển bản thân",
        ]}
        detailCourseOnline={detailCourseOnline}
        handleRedirect={(pharseIndex, videoIndex) =>
          handleRedirect(pharseIndex, videoIndex)
        }
        userData={userData}
        learnerReview={[
          { title: "Exceeded", rate: 90 },
          { title: "Yes", rate: 40 },
          { title: "Somewhat", rate: 60 },
          { title: "Not Really", rate: 33 },
          { title: "No", rate: 0 },
        ]}
      />
    </>
  ) : (
    // chưa xử lý loading
    <Loading></Loading>
  );
}

export default Tower1;

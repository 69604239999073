import React from "react";

function Home() {
  // Loading Data
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1200);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  return <>{<p>Home </p>}</>;
}

export default Home;

import {
  ADD_TO_CART_PRODUCT,
  ADD_TO_CART_SHIRT,
  DESCREASE_AMOUNT_CART_PRODUCT,
  FETCH_CATEGORY_PRODUCT,
  FETCH_PRODUCT,
  INCREASE_AMOUNT_CART_PRODUCT,
  REMOVE_CART_PRODUCT,
  REMOVE_ALL_PRODUCT,
  ADD_TO_CART_WITH_COLOR,
} from "core/redux/constant/productConstants";

// Khởi tạo State
const initialState = {
  category: [],
  products: [],
  cart: [],
};

export const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_PRODUCT: {
      state.category = action.payload;

      return { ...state };
    }

    case FETCH_PRODUCT: {
      state.products = action.payload;

      return { ...state };
    }

    case ADD_TO_CART_PRODUCT: {
      const productList = [...state.products];
      const cartList = [...state.cart];

      const prodIndex = cartList.findIndex(
        (prod) => prod.id === action.payload
      );

      if (prodIndex === -1) {
        const findProd = productList.find((prod) => prod.id === action.payload);

        cartList.push({ ...findProd, amount: 1 });
      } else {
        const findProd = cartList[prodIndex];

        findProd.amount += 1;
      }

      state.cart = cartList;

      return { ...state };
    }

    case ADD_TO_CART_SHIRT: {
      const { id, choiceSize } = action.payload;

      const productList = [...state.products];
      const cartList = [...state.cart];

      const prodIndex = cartList.findIndex((prod) => prod.id === id);

      if (prodIndex === -1) {
        const findProd = productList.find(
          (prod) => prod.id === id.slice(0, 20)
        );

        cartList.push({
          ...findProd,
          amount: 1,
          choiceSize: choiceSize,
          id: id,
        });
      } else {
        const findProd = cartList[prodIndex];

        findProd.amount += 1;
      }

      state.cart = cartList;

      return { ...state };
    }

    case REMOVE_CART_PRODUCT: {
      const cartList = [...state.cart];

      const prodIndex = cartList.findIndex(
        (prod) => prod.id === action.payload
      );

      cartList.splice(prodIndex, 1);

      state.cart = cartList;

      return { ...state };
    }

    case INCREASE_AMOUNT_CART_PRODUCT: {
      const cartList = [...state.cart];

      const findProduct = cartList.find((prod) => prod.id === action.payload);

      findProduct.amount += 1;

      state.cart = cartList;

      return { ...state };
    }

    case DESCREASE_AMOUNT_CART_PRODUCT: {
      const cartList = [...state.cart];

      const findProduct = cartList.find((prod) => prod.id === action.payload);

      if (findProduct.amount > 1) {
        findProduct.amount -= 1;
      }

      state.cart = cartList;

      return { ...state };
    }

    case REMOVE_ALL_PRODUCT: {
      state.cart = [];
      return { ...state };
    }

    case ADD_TO_CART_WITH_COLOR: {
      const { id, choiceColor } = action.payload;

      const productList = [...state.products];
      const cartList = [...state.cart];

      const prodIndex = cartList.findIndex((prod) => prod.id === id);

      if (prodIndex === -1) {
        const findProd = productList.find(
          (prod) => prod.id === id.slice(0, 20)
        );

        cartList.push({
          ...findProd,
          amount: 1,
          choiceColor: choiceColor,
          id: id,
        });
      } else {
        const findProd = cartList[prodIndex];

        findProd.amount += 1;
      }

      state.cart = cartList;

      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

import React from "react";

import "./styles/styles.scss";
import { NavLink } from "react-router-dom";

function InputPM({
  type,
  label,
  name,
  wrapperClassProps,
  onChangeProps,
  defaultOption,
  options,
  checkboxText,
  error,
  onChange,
  url,
  urlTitle,
}) {
  const InputType = (type) => {
    switch (type) {
      case "text":
        return (
          <div className={`wrapper-input_text ${wrapperClassProps}`}>
            <label>{label}</label>
            <input type={type} name={name} onChange={onChange} />

            <p className="text-danger">{error}</p>
          </div>
        );

      case "select":
        return (
          <div className={`wrapper-input_text ${wrapperClassProps}`}>
            <label>{label}</label>
            <select name={name} onChange={onChangeProps}>
              <option value="">{defaultOption}</option>

              {options?.map((option) => {
                return (
                  <option value={option.code} key={option.name}>
                    {option.name}
                  </option>
                );
              })}
            </select>

            <p className="text-danger">{error}</p>
          </div>
        );

      case "textarea":
        return (
          <div className={`wrapper-input_text ${wrapperClassProps}`}>
            <label>{label}</label>
            <textarea type={type} name={name} />
          </div>
        );

      case "checkbox":
        return (
          <div className={`control ${wrapperClassProps}`}>
            <label className="label">
              <input
                className="input-text"
                type={type}
                name={name}
                onChange={onChange}
              />
              <span>
                {checkboxText}

                {url && (
                  <NavLink to={url} target="_blank" rel="noopener noreferrer">
                    {urlTitle}
                  </NavLink>
                )}
              </span>
            </label>

            <p className="text-danger">{error}</p>
          </div>
        );

      default:
        break;
    }
  };
  return <>{InputType(type)}</>;
}

export default InputPM;

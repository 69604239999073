export const GET_QUESTION = "GET_QUESTION";
export const QUESTION_CREATE = "QUESTION_CREATE";
export const QUESTION_UPDATE_IN_LIST = "UPDATE_QUESTION_IN_LIST";
export const QUESTION_LOADING = "QUESTION_LOADING";
export const QUESTION_LOADED = "QUESTION_LOADED";
export const QUESTION_DELETE = "QUESTION_DELETE";
// FETCH ALL COURSE
export const FETCH_QUESTION_REQUESTING = "FETCH_QUESTION_REQUESTING";
export const FETCH_MORE_QUESTION_SUCCESS = "FETCH_MORE_QUESTION_SUCCESS";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const FETCH_QUESTION_FAILED = "FETCH_QUESTION_FAILED";

import React from "react";
import { statusMessage } from "app/const/statusPayment";
import ZaloImg from "assets/images/ZALO.png";
import FBImg from "assets/images/facebook.jpg";
import "./styles.scss";
function FailedTransaction({ status, timeStamp }) {
  const statusMgs = statusMessage.find((code) => code.code === status.RspCode);
  React.useEffect(() => { }, []);
  const handleFbClick = () => {
    window.open(
      "https://www.facebook.com/theoriginsacademy",
      "_blank"
    );
  };
  const handleZaloClick = () => {
    window.open(
      "https://zalo.me/g/bqufiq446",
      "_blank"
    );
  };
  return (
    <div className="wrapper-content container mb-5 mt-5">
      <i className="fal fa-times"></i>
      <div className="product">
        <div className="content-body_product">
          <h3 className="mt-4">Thanh toán của bạn thất bại</h3>
          <p className="mt-4">{statusMgs?.message}</p>
        </div>
      </div>
      <div className="container d-flex flex-column align-items-center mt-5">
        <div className="col-9 d-flex justify-content-center">
          <div className="row d-flex w-100" style={{ backgroundColor: "blue", alignItems: "center", height: "40px" }}>
            <p className="m-0 text-center" style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white", flex: 1 }}>
              Hãy cùng tham gia nhóm cùng Vasanth Gopalan
            </p>
          </div>
        </div>
        <div className="container mt-10 d-flex ">
          <div className="col-6 d-flex w-100 px-20">
            <div className="card d-flex w-100" style={{ width: '18rem', borderRadius: '8px', overflow: 'hidden' }}>
              <img
                src={FBImg}
                className="card-img-top"
                alt="Card cap"
                style={{ width: '100%', height: 'auto' }}
              />
              <div className="card-body" style={{ padding: '1rem' }}>
                <h5 className="card-title">Tham gia cộng đồng để cập nhật thông tin giá trị từ Chuyên gia</h5>
                <p className="card-text" style={{ color: 'grey' }}>Nhóm Riêng Tư: 153.493 thành viên</p>
                <button className="btn btn-primary" style={{ width: '100%' }} onClick={handleFbClick}>Tham Gia Nhóm</button>
              </div>
            </div>
          </div>

          <div className="col-6 d-flex w-100 px-20 text-right">
            <div className="card d-flex w-100" style={{ width: '18rem', borderRadius: '8px', overflow: 'hidden' }}>
              <img
                src={ZaloImg}
                className="card-img-top"
                alt="Card cap"
                style={{ width: '100%', height: 'auto' }}
              />
              <div className="card-body" style={{ padding: '1rem' }}>
                <h5 className="card-title">Tham gia nhóm zalo để đăng ký và cập nhật thông tin</h5>
                <p className="card-text" style={{ color: 'grey' }}>Nhóm Riêng Tư: 153.493 thành viên</p>
                <button className="btn btn-primary" style={{ width: '100%' }} onClick={handleZaloClick}>Tham Gia Nhóm</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default FailedTransaction;

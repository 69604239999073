import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StationDisplay = ({ icon, updatedColor, topIcon, hoverColor = '#FF0000', leftIcon, rightIcon, size = '2x', onClick }) => {
    const defaultColor = '#808080';
    const [currentColor, setCurrentColor] = useState(defaultColor);

    useEffect(() => {
        setCurrentColor(updatedColor || defaultColor);
    }, [updatedColor]);

    const iconStyle = {
        position: 'absolute',
        top: topIcon || '0',
        color: currentColor,
        zIndex: 20,
        cursor: 'pointer',
        ...(leftIcon !== undefined ? { left: leftIcon } : { right: rightIcon }), // Use either left or right, not both
    };

    return (
        <div
            onMouseEnter={() => setCurrentColor(hoverColor)}
            onMouseLeave={() => setCurrentColor(updatedColor || defaultColor)}
            onClick={onClick}
        >
            <FontAwesomeIcon
                icon={icon}
                size={size}
                style={iconStyle}
                className="icon"
            />
        </div>
    );
};

export default StationDisplay;
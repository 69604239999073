import cloneDeep from "lodash/cloneDeep";
import {
  SCHEDULE_COURSE_ONLINE_CREATE,
  SCHEDULE_COURSE_ONLINE_UPDATE_IN_LIST,
  SCHEDULE_COURSE_ONLINE_HANDLE_USER_CHANGE_PERMISSION,
  SCHEDULE_COURSE_ONLINE_HANDLE_ADD_USER_TO_SCHEDULE,
  SCHEDULE_COURSE_ONLINE_HANDLE_DEL_USER_FROM_SCHEDULE,
  SCHEDULE_COURSE_ONLINE_HANDLE_USER_ANSWER_QUESTION,
  // SCHEDULE_COURSE_ONLINE_HANDLE_USER,
  // SCHEDULE_COURSE_ONLINE_LOADED,
  // SCHEDULE_COURSE_ONLINE_LOADING,
  SCHEDULE_COURSE_ONLINE_DELETE,
  FETCH_SCHEDULE_COURSE_ONLINE_REQUESTING,
  FETCH_SCHEDULE_COURSE_ONLINE_SUCCESS,
  // FETCH_MORE_SCHEDULE_COURSE_ONLINE_SUCCESS,
  FETCH_SCHEDULE_COURSE_ONLINE_FAILED,
  FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_SUCCESS,
  FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_FAILED,
} from "core/redux/constant/scheduleCourseOnlineConstant";
import { isArray } from "lodash";

const initialState = {
  scheduleCourseOnlineList: [],
  detailScheduleCourseOnline: {},
  records: 0,
  hasMoreItems: false,
  loadingScheduleCourseOnline: true,
  error: null,
};

export const scheduleCourseOnlineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE_COURSE_ONLINE_REQUESTING: {
      return { ...state, loadingScheduleCourseOnline: true };
    }
    case FETCH_SCHEDULE_COURSE_ONLINE_SUCCESS: {
      let hasMoreItems = action.payload.total > action.payload.data?.length;
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        scheduleCourseOnlineList: action.payload,
        records: action.payload.total,
        hasMoreItems,
      };
    }
    case FETCH_SCHEDULE_COURSE_ONLINE_FAILED: {
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        error: action.payload,
      };
    }
    case FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_SUCCESS: {
      const data = action.payload.data[0];
      const courseOnline = isArray(data.courseOnline)
        ? data.courseOnline[0]
        : data.courseOnline;
      data.courseOnline = courseOnline;
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        detailScheduleCourseOnline: data,
      };
    }
    case FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_FAILED: {
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        detailScheduleCourseOnline: {},
      };
    }
    case SCHEDULE_COURSE_ONLINE_CREATE: {
      const list = cloneDeep(state.scheduleCourseOnlineList);
      list.data.push(action.payload.data);
      return {
        ...state,
        scheduleCourseOnlineList: list,
        loadingScheduleCourseOnline: false,
      };
    }
    case SCHEDULE_COURSE_ONLINE_DELETE: {
      const list = cloneDeep(state.scheduleCourseOnlineList);
      const itemIndex = list.data.findIndex(
        (y) => y._id === action.payload.data._id
      );
      if (itemIndex !== -1) {
        list.data.splice(itemIndex, 1);
      }

      return {
        ...state,
        scheduleCourseOnlineList: list,
        loadingScheduleCourseOnline: false,
      };
    }
    case SCHEDULE_COURSE_ONLINE_UPDATE_IN_LIST: {
      const list = cloneDeep(state.scheduleCourseOnlineList);
      if (list && list.length > 0) {
        const itemIndex = list.data.findIndex(
          (y) => y._id === action.payload.data._id
        );

        if (itemIndex !== -1) {
          list.data[itemIndex] = {
            ...list.data[itemIndex],
            ...action.payload.data,
          };
        }
        return {
          ...state,
          scheduleCourseOnlineList: list,
          loadingScheduleCourseOnline: false,
          detailScheduleCourseOnline: action.payload.data,
        };
      } else {
        return {
          ...state,
          loadingScheduleCourseOnline: false,
          detailScheduleCourseOnline: action.payload.data,
        };
      }
    }
    // case SCHEDULE_COURSE_ONLINE_HANDLE_USER: {
    //   const newData = cloneDeep(state.detailScheduleCourseOnline);
    //   newData.studentList = action.payload.data;
    //   return {
    //     ...state,
    //     loadingScheduleCourseOnline: false,
    //     detailScheduleCourseOnline: newData,
    //   };
    // }
    case SCHEDULE_COURSE_ONLINE_HANDLE_USER_CHANGE_PERMISSION: {
      const newData = cloneDeep(state.detailScheduleCourseOnline);
      console.log("newData", newData);
      console.log("action.payload", action.payload);
      const index = newData.studentList?.findIndex(
        (stu) => stu._id === action.payload.data?._id
      );
      if (index !== -1) {
        newData.studentList[index] = action.payload.data;
      }
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        detailScheduleCourseOnline: newData,
      };
    }
    case SCHEDULE_COURSE_ONLINE_HANDLE_ADD_USER_TO_SCHEDULE: {
      const newData = cloneDeep(state.detailScheduleCourseOnline);
      newData.studentList = action.payload.data;
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        detailScheduleCourseOnline: newData,
      };
    }
    case SCHEDULE_COURSE_ONLINE_HANDLE_DEL_USER_FROM_SCHEDULE: {
      const newData = cloneDeep(state.detailScheduleCourseOnline);
      newData.studentList = action.payload.data;
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        detailScheduleCourseOnline: newData,
      };
    }
    case SCHEDULE_COURSE_ONLINE_HANDLE_USER_ANSWER_QUESTION: {
      const newData = cloneDeep(state.detailScheduleCourseOnline);
      newData.studentList = action.payload.data.studentList;
      return {
        ...state,
        loadingScheduleCourseOnline: false,
        detailScheduleCourseOnline: newData,
      };
    }
    default: {
      return { ...state };
    }
  }
};

// Inter Bank
import Amex from "assets/images/source/accountDetails/InternationalCard/AMEX.png";
import JCB from "assets/images/source/accountDetails/InternationalCard/JCB.png";
import MasterCard from "assets/images/source/accountDetails/InternationalCard/MASTERCARD.png";
import UPI from "assets/images/source/accountDetails/InternationalCard/UPI.png";
import VISA from "assets/images/source/accountDetails/InternationalCard/VISA.png";
// local bank

import abbank from "assets/images/source/accountDetails/LocalCard/abbank.png";
import acb from "assets/images/source/accountDetails/LocalCard/acb.png";
import agribank from "assets/images/source/accountDetails/LocalCard/agribank.png";
import bacabank from "assets/images/source/accountDetails/LocalCard/bacabank.png";
import baovietbank from "assets/images/source/accountDetails/LocalCard/baovietbank.png";
import bidv from "assets/images/source/accountDetails/LocalCard/bidv.png";
import dongabank from "assets/images/source/accountDetails/LocalCard/dongabank.png";
import eximbank from "assets/images/source/accountDetails/LocalCard/eximbank.png";
import gpbank from "assets/images/source/accountDetails/LocalCard/gpbank.png";
import hdbank from "assets/images/source/accountDetails/LocalCard/hdbank.png";
import ivb from "assets/images/source/accountDetails/LocalCard/ivb.png";
import kienlongbank from "assets/images/source/accountDetails/LocalCard/kienlongbank.png";
import lienvietbank from "assets/images/source/accountDetails/LocalCard/lienvietbank.png";
import mafc from "assets/images/source/accountDetails/LocalCard/mafc.png";
import mbbank from "assets/images/source/accountDetails/LocalCard/mbbank.png";
import msbank from "assets/images/source/accountDetails/LocalCard/msbank.png";
import namabank from "assets/images/source/accountDetails/LocalCard/namabank.png";
import ncb from "assets/images/source/accountDetails/LocalCard/ncb.png";
import ocb from "assets/images/source/accountDetails/LocalCard/ocb.png";
import ojb from "assets/images/source/accountDetails/LocalCard/ojb.png";
import pgbank from "assets/images/source/accountDetails/LocalCard/pgbank.png";
import pvcombank from "assets/images/source/accountDetails/LocalCard/pvcombank.png";
import sacombank from "assets/images/source/accountDetails/LocalCard/sacombank.png";
import saigonbank from "assets/images/source/accountDetails/LocalCard/saigonbank.png";
import scb from "assets/images/source/accountDetails/LocalCard/scb.png";
import seabank from "assets/images/source/accountDetails/LocalCard/seabank.png";
import shb from "assets/images/source/accountDetails/LocalCard/shb.png";
import shinhanbank from "assets/images/source/accountDetails/LocalCard/shinhanbank.png";
import techcombank from "assets/images/source/accountDetails/LocalCard/techcombank.png";
import tpbank from "assets/images/source/accountDetails/LocalCard/tpbank.png";
import uob from "assets/images/source/accountDetails/LocalCard/uob.png";
import vib from "assets/images/source/accountDetails/LocalCard/vib.png";
import vidbank from "assets/images/source/accountDetails/LocalCard/vidbank.png";
import vietabank from "assets/images/source/accountDetails/LocalCard/vietabank.png";
import vietbank from "assets/images/source/accountDetails/LocalCard/vietbank.jpg";
import vietcapitalbank from "assets/images/source/accountDetails/LocalCard/vietcapitalbank.jpg";
import vietcombank from "assets/images/source/accountDetails/LocalCard/vietcombank.jpg";
import vietcredit from "assets/images/source/accountDetails/LocalCard/vietcredit.jpg";
import vietinbank from "assets/images/source/accountDetails/LocalCard/vietinbank.jpg";
import vpbank from "assets/images/source/accountDetails/LocalCard/vpbank.jpg";
import vrb from "assets/images/source/accountDetails/LocalCard/vrb.png";
import wooribank from "assets/images/source/accountDetails/LocalCard/wooribank.png";
import VNPay from "assets/images/source/accountDetails/vnpay.png";
import Bank from "assets/images/source/accountDetails/bank.png";
import Card from "assets/images/source/accountDetails/card.png";
import ViVNPay from "assets/images/source/accountDetails/vivnpay.png";
export const payment = [
  {
    title: "Ứng dụng thanh toán hỗ trợ VNPAY",
    img: VNPay,
    type: "vnpay",
  },
  {
    title: "Thẻ nội địa & tài khoản ngân hàng",
    img: Bank,
    type: "bank",
  },
  // {
  //   title: "Thẻ thanh toán quốc tế",
  //   img: Card,
  //   type: "card",
  // },
  {
    title: "Ví điện tử VNPAY",
    img: ViVNPay,
    type: "vivnpay",
  },
];

export const listLocalBank = [
  {
    title: "abbank",
    img: abbank,
  },
  {
    title: "acb",
    img: acb,
  },
  {
    title: "agribank",
    img: agribank,
  },
  {
    title: "bacabank",
    img: bacabank,
  },
  {
    title: "baovietbank",
    img: baovietbank,
  },
  {
    title: "bidv",
    img: bidv,
  },
  {
    title: "dongabank",
    img: dongabank,
  },
  {
    title: "eximbank",
    img: eximbank,
  },
  {
    title: "gpbank",
    img: gpbank,
  },
  {
    title: "hdbank",
    img: hdbank,
  },
  {
    title: "ivb",
    img: ivb,
  },
  {
    title: "kienlongbank",
    img: kienlongbank,
  },
  {
    title: "lienvietbank",
    img: lienvietbank,
  },
  {
    title: "mafc",
    img: mafc,
  },
  {
    title: "mbbank",
    img: mbbank,
  },
  {
    title: "msbank",
    img: msbank,
  },
  {
    title: "namabank",
    img: namabank,
  },
  {
    title: "ncb",
    img: ncb,
  },
  {
    title: "ocb",
    img: ocb,
  },
  {
    title: "ojb",
    img: ojb,
  },
  {
    title: "pgbank",
    img: pgbank,
  },
  {
    title: "pvcombank",
    img: pvcombank,
  },
  {
    title: "sacombank",
    img: sacombank,
  },
  {
    title: "saigonbank",
    img: saigonbank,
  },
  {
    title: "scb",
    img: scb,
  },
  {
    title: "seabank",
    img: seabank,
  },
  {
    title: "shb",
    img: shb,
  },
  {
    title: "shinhanbank",
    img: shinhanbank,
  },
  {
    title: "techcombank",
    img: techcombank,
  },
  {
    title: "tpbank",
    img: tpbank,
  },
  {
    title: "uob",
    img: uob,
  },
  {
    title: "vib",
    img: vib,
  },
  {
    title: "vidbank",
    img: vidbank,
  },
  {
    title: "vietabank",
    img: vietabank,
  },
  {
    title: "vietcapitalbank",
    img: vietcapitalbank,
  },
  {
    title: "vietcombank",
    img: vietcombank,
  },
  {
    title: "vietcredit",
    img: vietcredit,
  },
  {
    title: "vietinbank",
    img: vietinbank,
  },
  {
    title: "vpbank",
    img: vpbank,
  },
  {
    title: "vietbank",
    img: vietbank,
  },
  {
    title: "vrb",
    img: vrb,
  },
  {
    title: "wooribank",
    img: wooribank,
  },
];

export const listInterBank = [
  { title: "Amex", img: Amex },
  { title: "JCB", img: JCB },
  { title: "MasterCard", img: MasterCard },
  { title: "UPI", img: UPI },
  { title: "VISA", img: VISA },
];

// https://sandbox.vnpayment.vn/paymentv2/vpcpay.html?vnp_Amount=1000000&vnp_Command=pay&vnp_CreateDate=20240805132253&vnp_CurrCode=VND&vnp_ExpireDate=20240805133753&vnp_IpAddr=14.161.32.157&vnp_Locale=vn&vnp_OrderInfo=Thanh+toan+don+hang+thoi+gian%3A+2024-08-05+11%3A15%3A58&vnp_OrderType=topup&vnp_ReturnUrl=https%3A%2F%2Fsandbox.vnpayment.vn%2Ftryitnow%2FHome%2FVnPayReturn&vnp_TmnCode=CTTVNP01&vnp_TxnRef=201388&vnp_Version=2.1.1&vnp_SecureHash=37bc3e2b225ceeac0a960eacf17d0c6a1e47eeb220dadffef04b5c916cc611d2dddcecf08208f017482e25b1c672c207e189f209d3de0b6a8eb3cfdb0ef21a52aac5d27d096211cd618613db5f6cecd764542eadc1bce0d9e9f863be51fd838a5e1fd1c0eb5666a90bea17e8ab8d60068f837e9fae770a79b121779c32af1ee8b62089690eab6c45c26526a5ea911c5c06bd552f06073d1e5548a547186e47a4d39e796f19240561a77649833dc4bb668231db06b10ce2b7f865716148077f99556e38d29dd3d17347ebabc7694d8b9ade62ea5188cd5505c1eb299f4240a3e2fbf84b78803be5493bfc5e48a2fb46e7705270fcb9fde7b4300257b0f75592d8

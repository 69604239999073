import { useEffect, useState } from "react";
import "./styles.scss"; // Create a CSS file for styles

const WordByWord = ({ text, index }) => {
  const [words, setWords] = useState([]);

  useEffect(() => {
    // Split the text into words and store them in the state
    const wordArray = text.split(" ");
    setWords(wordArray);
  }, [text]);

  return (
    <div className="word-by-word" key={index}>
      {words.map((word, index) => (
        <span
          key={index}
          className="word"
          style={{ animationDelay: `${index * 0.05}s` }}
        >
          {word}
        </span>
      ))}
    </div>
  );
};

export default WordByWord;

import {
  qBank,
  qBank2,
  qBank3,
  qBank4,
  qBank5,
} from "../components/questionBank";
export const courseOnline = {
  name: "Tháp 1",
  code: "T1-01",
  status: "",
  timeStart: "",
  timeEnd: "",
  level: 1,
  released: "01 Jan 2024",
  star: 4,
  rating: 392,
  totalUser: 2,
  student: 30,
  listUser: [
    {
      idUser: "656983d6457e14e12f22eadc",
      inforCourse: {
        title: "Tháp 1",
        courseId: "",
        totalPaid: 1000000,
        rank: 1,
        updateTime: "",
        percentComplete: "65%",
        listFloor: [
          {
            title: "Tầng 1",
            floor: 1,
            paid: 0,
            havePermission: true,
            totalScore: 0,
            status: "paided",
            listVideo: [
              {
                id: 1,
                name: "NLP là gì?",
                url: "https://player.vimeo.com/video/897490048?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                testAt: "3111",
                question: qBank,
                time: 843,
                timeViewed: 600,
                score: 0,
                isVisible: true,
                floor: 1,
              },
              {
                id: 2,
                name: "Neo cảm xúc là gì?",
                url: "https://player.vimeo.com/video/901714138?h=0931e2235d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                time: 913.8,
                question: qBank2,
                score: 0,
                floor: 1,
              },
              {
                id: 3,
                name: "Re-Frame là gì?",
                url: "https://player.vimeo.com/video/897488404?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                time: 849.6,
                question: qBank3,
                score: 0,
                floor: 1,
              },
              {
                id: 4,
                name: "Obsession là gì?",
                url: "https://player.vimeo.com/video/897488997?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank4,
                time: 849.6,
                score: 0,
                floor: 1,
              },
              {
                id: 5,
                name: "Part 5",
                url: "https://player.vimeo.com/video/897487983?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                time: 913.8,
                question: qBank5,
                score: 0,
                floor: 1,
              },
            ],
          },
          {
            title: "Tầng 2",
            status: "",
            floor: 2,
            paid: 0,
            havePermission: false,
            totalScore: 0,
            listVideo: [
              {
                name: "30p - Video 1",
                url: "https://player.vimeo.com/video/897511310?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: true,
                testAt: "3111",
                time: 913.8,
                question: qBank,
                score: 0,
                floor: 2,
              },
              {
                name: "30p - Video 2",
                url: "https://player.vimeo.com/video/901714354?h=af4b55b6b3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                time: 913.8,
                question: qBank,
                score: 0,
                floor: 2,
              },
              {
                name: "30p - Video 3",
                url: "https://player.vimeo.com/video/897524043?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                time: 913.8,
                question: qBank,
                score: 0,
                floor: 2,
              },
            ],
          },
        ],
      },
    },
    {
      id: "656983d6457e14e12f22eadd",
      inforCourse: {
        title: "Tháp 1",
        courseId: "",
        totalPaid: 1000000,
        rank: 1,
        listFloor: [
          {
            title: "Tầng 1",
            floor: 1,
            paid: 0,
            havePermission: true,
            totalScore: 0,
            status: "paided",
            listVideo: [
              {
                id: 1,
                name: "NLP là gì? - 15p",
                url: "https://player.vimeo.com/video/897490048?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                testAt: "3111",
                question: qBank,
                score: 0,
                isVisible: true,
                floor: 1,
              },
              {
                id: 2,
                name: "Neo cảm xúc là gì? - 15p",
                url: "https://player.vimeo.com/video/901714138?h=0931e2235d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank2,
                score: 0,
                floor: 1,
              },
              {
                id: 3,
                name: "Re-Frame là gì?",
                url: "https://player.vimeo.com/video/897488404?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank3,
                score: 0,
                floor: 1,
              },
              {
                id: 4,
                name: "Obsession là gì?",
                url: "https://player.vimeo.com/video/897488997?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank4,
                score: 0,
                floor: 1,
              },
              {
                id: 5,
                name: "Part 5",
                url: "https://player.vimeo.com/video/897487983?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank5,
                score: 0,
                floor: 1,
              },
            ],
          },
          {
            title: "Tầng 2",
            status: "",
            floor: 2,
            paid: 0,
            havePermission: false,
            totalScore: 0,
            listVideo: [
              {
                name: "30p - Video 1",
                url: "https://player.vimeo.com/video/897511310?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: true,
                testAt: "3111",
                question: qBank,
                score: 0,
                floor: 2,
              },
              {
                name: "30p - Video 2",
                url: "https://player.vimeo.com/video/901714354?h=af4b55b6b3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank,
                score: 0,
                floor: 2,
              },
              {
                name: "30p - Video 3",
                url: "https://player.vimeo.com/video/897524043?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                isVisible: false,
                testAt: "3111",
                question: qBank,
                score: 0,
                floor: 2,
              },
            ],
          },
        ],
      },
    },
  ],
};

import React, { useEffect, useState } from "react";
import "./styles.scss";
function FormCountDownt(props) {
  const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
      countDownDate - new Date().getTime()
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);

      return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
  };

  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const [days, hours, minutes, seconds] = useCountdown(props.date);
  const renderTime = (time, title) => {
    return (
      <div className="element">
        <div className="countdown-background countdown-text">
          {time >= 10 ? time : `0` + time}
        </div>
        <div className="paragraph">{title}</div>
      </div>
    );
  };
  return seconds >= 0 ? (
    <div className="row mt-3">
      <h4 className="paragraph transition time-promotion">THỜI GIAN ƯU ĐÃI</h4>
      <div className="paragraph-description mb-3">
        Sau thời gian này giá vé sẽ trở về giá niêm yết
      </div>
      <div className="countdown">
        {renderTime(days, "Ngày")}
        {renderTime(hours, "Giờ")}
        {renderTime(minutes, "Phút")}
        {renderTime(seconds, "Giây")}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default FormCountDownt;

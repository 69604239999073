import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import playButton from "assets/images/source/course-media/playButton.png";
// import Film from "assets/images/source/course-media/film.png";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import FormatNumberic from "share/formatNumber";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { useState } from "react";
import { Button } from "@mui/material";
import { useEffect } from "react";
import _, { isEmpty } from "lodash";
import "./styles.scss";
function ContentLessonCourseMedia(props) {
  const { userData, handleRegister } = props;
  const [data, setData] = useState([]);
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    if (userData && userData.lesson && userData.lesson.length > 0) {
      setData(props.userData);
    } else setData(props.detailCourseOnline);
  }, [props.userData]);

  const handleShowContent = (type, index) => {
    let newData = _.cloneDeep(data);
    newData.lesson[index].isView = type;
    setData(newData);
  };
  const renderItem = (pharse, index) => {
    return (
      <div className="mt-5" key={index}>
        <div className="d-flex justify-content-between header">
          <div>
            <div style={{ color: "#0463C3" }}>{pharse.pharse}</div>
            <div
              className={" title-h3 title-lesson-" + index}
              style={{
                textTransform: "uppercase",
              }}
            >
              {pharse.title}
            </div>
          </div>
          <div className="d-flex justify-content-between description-gray align-items-center">
            {pharse.permission ? (
              <div className=" description-gray mr-2"></div>
            ) : (
              <Button
                className={
                  "button-primary mr-2 animate-bounce background-lesson-" + 1
                }
                style={{
                  fontSize: "16px",
                }}
                variant="contained"
                onClick={(e) => {
                  handleRegister(e, pharse);
                }}
              >
                <FormatNumberic num={pharse.price} />
              </Button>
            )}
            {pharse.isView && pharse.videoList?.length > 0 ? (
              <Button
                onClick={() => {
                  handleShowContent(false, index);
                }}
              >
                <ExpandLessIcon />
              </Button>
            ) : pharse.videoList?.length > 0 ? (
              <Button
                className="pl-2"
                onClick={() => {
                  handleShowContent(true, index);
                }}
              >
                <ExpandMoreIcon />
              </Button>
            ) : (
              <Button
                className="pl-2"
                disabled={true}
                onClick={() => {
                  handleShowContent(true, index);
                }}
              ></Button>
            )}
          </div>
        </div>
        <div className="content">
          {pharse.isView &&
            pharse?.videoList?.map((item2, idx2) => {
              const hours = Math.floor(item2.duration / 3600);
              const minutes = Math.floor((item2.duration % 3600) / 60);
              const second = (item2.duration % 3600) % 60;
              return isEmpty(item2) ? (
                <div key={idx2}></div>
              ) : (
                <div
                  className={
                    `mt-3 d-flex justify-content-between ` +
                    (pharse.permission ? "pointer" : " ")
                  }
                  key={item2._id}
                  onClick={() => {
                    pharse.permission && props?.handleRedirect(index, idx2);
                  }}
                >
                  <div className="d-flex" style={{ maxWidth: "80%" }}>
                    <FlightTakeoffIcon
                      className={"mr-2 icon-gradient-" + index}
                    />
                    Bài học
                    {" : " + item2.name}
                  </div>
                  <div
                    className="description-gray d-flex"
                    style={{ textAlign: "end" }}
                  >
                    <div>
                      {pharse.permission ? (
                        <div className="d-flex  mr-4">
                          {!!hours && hours + "h "}{" "}
                          {!!minutes && minutes + "m "}
                          {!!second && +second + "s"}
                          <PlayCircleIcon
                            width={20}
                            height={20}
                            className="ml-3 pointer"
                            sx={{
                              color: item2.answerList
                                ? "rgb(0, 171, 85)"
                                : "blue",
                            }}
                            onClick={() => {
                              props?.handleRedirect(index, idx2);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="pr-4">
                          {!!hours && hours + "h "}{" "}
                          {!!minutes && minutes + "m "}
                          {!!second && +second + "s"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="title-h2 box-title ">Nội dung khóa học</div>
      </div>
      {/* <div className="description-gray">3 hours. 3 packages. 14 lessons.</div> */}
      <div className="mt-4">
        {data?.lesson?.length > 0
          ? data.lesson.map((pharse, index) => {
              return renderItem(pharse, index);
            })
          : props.detailCourseOnline?.lesson?.length > 0 &&
            props.detailCourseOnline?.lesson.map((pharse, index) => {
              return renderItem(pharse, index);
            })}
      </div>
    </div>
  );
}

export default ContentLessonCourseMedia;

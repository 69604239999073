export const GET_VIDEO = "GET_VIDEO";
export const GET_VIDEO_DETAIL = "GET_VIDEO_DETAIL";
export const VIDEO_CREATE = "VIDEO_CREATE";
export const VIDEO_UPDATE_IN_LIST = "UPDATE_VIDEO_IN_LIST";
export const VIDEO_LOADING = "VIDEO_LOADING";
export const VIDEO_LOADED = "VIDEO_LOADED";
export const VIDEO_DELETE = "VIDEO_DELETE";
// FETCH ALL COURSE
export const FETCH_VIDEO_REQUESTING = "FETCH_VIDEO_REQUESTING";
export const FETCH_MORE_VIDEO_SUCCESS = "FETCH_MORE_VIDEO_SUCCESS";
export const FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS";
export const FETCH_VIDEO_FAILED = "FETCH_VIDEO_FAILED";

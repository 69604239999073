import React from "react";

function CheckboxIconSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="34"
      viewBox="0 0 31 34"
      fill="none"
    >
      <path
        d="M28 0.518921H0V29.5189H28V0.518921Z"
        fill={props.backgroundColor}
      />
      <path
        d="M30 0.518921H1C0.447715 0.518921 0 0.966636 0 1.51892V32.5189C0 33.0712 0.447715 33.5189 1 33.5189H30C30.5523 33.5189 31 33.0712 31 32.5189V1.51892C31 0.966636 30.5523 0.518921 30 0.518921Z"
        fill={props.backgroundColor}
      />
      <path
        d="M13.972 20.9999C13.8638 21.102 13.7207 21.1588 13.572 21.1588C13.4233 21.1588 13.2802 21.102 13.172 20.9999L10 17.9789C9.92202 17.9064 9.85982 17.8186 9.81729 17.7209C9.77476 17.6233 9.75281 17.5179 9.75281 17.4114C9.75281 17.3049 9.77476 17.1996 9.81729 17.1019C9.85982 17.0043 9.92202 16.9165 10 16.8439L10.4 16.4659C10.5619 16.3148 10.7751 16.2307 10.9965 16.2307C11.218 16.2307 11.4312 16.3148 11.593 16.4659L13.574 18.3519L18.933 13.2519C19.0949 13.1008 19.3081 13.0167 19.5295 13.0167C19.751 13.0167 19.9642 13.1008 20.126 13.2519L20.526 13.6299C20.604 13.7025 20.6662 13.7903 20.7087 13.8879C20.7513 13.9856 20.7732 14.0909 20.7732 14.1974C20.7732 14.3039 20.7513 14.4093 20.7087 14.5069C20.6662 14.6046 20.604 14.6924 20.526 14.7649L13.972 20.9999Z"
        fill={props.color}
      />
    </svg>
  );
}

export default CheckboxIconSvg;

import { useTranslation } from "react-i18next";
import anh0 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh0.jpg";
import anh1 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh1.jpg";
import anh2 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh2.jpg";
import anh3 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh3.jpg";
import anh4 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh4.jpg";
import anh5 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh5.jpg";
import anh6 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh6.jpg";
import anh7 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh7.jpg";
import anh8 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh8.jpg";
import anh9 from "assets/images/course/course-offline/khoi-dau-thinh-vuong/anh9.jpg";
export const peoplePopup = [
  {
    name: "Nguyễn Hoàng Anh",
    phone: "034xxxxxxx",
    pic: anh0,
  },
  {
    name: "Lê Minh Khang",
    phone: "098xxxxxxx",
    pic: anh1,
  },
  {
    name: "Trần Khánh Linh",
    phone: "0938xxxxxx",
    pic: anh2,
  },
  {
    name: "Phạm Gia Huy",
    phone: "097xxxxxxx",
    pic: anh3,
  },
  {
    name: "Bùi Thiên An",
    phone: "098xxxxxxx",
    pic: anh4,
  },
  {
    name: "Đỗ Hải Yến",
    phone: "0378xxxxxx",
    pic: anh5,
  },
  {
    name: "Vũ Tuấn Kiệt",
    phone: "039xxxxxxx",
    pic: anh6,
  },
  {
    name: "Phan Ngọc Bích",
    phone: "098xxxxxxx",
    pic: anh7,
  },

  {
    name: "Võ Anh Dũng",
    phone: "0937xxxxxx",
    pic: anh8,
  },

  {
    name: "Dương Thảo Vy",
    phone: "098xxxxxxx",
    pic: anh9,
  },
];


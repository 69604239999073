import { question } from "fontawesome";
import { result } from "lodash";

export const colorsData = [
    { color: '#0000FF', text: 'Khóa đã học/miễn phí' },
    { color: '#FF0000', text: 'Khóa đang chọn' },
    // { color: '#FFFF2E', text: 'Khóa theo gói' },
];
export const pyramidItemsLayout = [
    [{ label: 10, color: '#808080' }],
    [{ label: 8, color: '#808080' }, { label: 9, color: '#808080' }],
    [{ label: 7, color: '#808080' }, { label: 6, color: '#808080' }, { label: 5, color: '#808080' }],
    [{ label: 1, color: '#0000FF' }, { label: 2, color: '#808080' }, { label: 3, color: '#808080' }, { label: 4, color: '#808080' }]
];

export const comboDataDetails = [
    { defaultText: "Full trạm" },
    { defaultText: "Trạm 1: Kỹ Năng" },
    { defaultText: "Trạm 2: Tư Duy" },
    { defaultText: "Trạm 3: Về Đích" },
];

export const stationItemsLayout = [
    {
        stationNameEng: 'Skill Station',
        stationNameVie: 'Trạm Kĩ Năng',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Mindset Station',
        stationNameVie: 'Trạm Tư Duy',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Finish Station',
        stationNameVie: 'Trạm Về Đích',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
];

export const videoInfoAndQuestion = [
    {
        id: 0,
        title: 'Bài 1: Đỉnh Núi Đầu Tiên',
        url: 'https://player.vimeo.com/video/1023161126',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Tại sao chúng ta cần NLP?',
                answerchoice: [
                    'A. Cuộc sống không công bằng và không có gì bạn có thể làm về điều đó',
                    'B. NLP là một món quà cho nhân loại, giúp mọi người thành công theo mọi cách',
                    'C. NLP được tạo ra cho những người thành công',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Một Núi Bài Học có bao nhiêu video?',
                answerchoice: [
                    'A. 10',
                    'B. 15',
                    'C. 20',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Tên Núi Bài Học bạn đang chinh phục có tên là gì?',
                answerchoice: [
                    'A. Kinabalu',
                    'B. Kilimanjaro',
                    'C. Fansipan',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 1,
        title: 'Bài 2: NLP là gì?',
        url: 'https://player.vimeo.com/video/1022785193',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: NLP được phát minh khi nào?',
                answerchoice: [
                    'A. 1988',
                    'B. 1970',
                    'C. 2010',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Phương pháp mà những nhà sáng lập tạo ra để nghiên cứu những người thành công từ khởi điểm thấp ban đầu gọi là…',
                answerchoice: [
                    'A. Máy tính',
                    'B. Y học',
                    'C. Mô hình xuất sắc',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Sự khác biệt giữa NLP và Tâm lý học là gì?',
                answerchoice: [
                    'A. Chúng giống nhau',
                    'B. NLP nghiên cứu cách mọi người thành công và do đó là một nghiên cứu về thành công. Tâm lý học nghiên cứu cách và tại sao con người bị rối loạn tâm thần',
                    'C. Tâm lý học tốt hơn cho sự thành công',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 2,
        title: 'Bài 3: Quyết Định/Kết Luận',
        url: 'https://player.vimeo.com/video/1022785665',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Điều gì là thứ duy nhất chúng ta đã làm từ khi sinh ra đến bây giờ…',
                answerchoice: [
                    'A. Học',
                    'B. Chơi',
                    'C. Ra quyết định',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Chức năng chính của não bộ chúng ta là gì?',
                answerchoice: [
                    'A. Thở và ngủ',
                    'B. Ghi nhớ và sáng tạo',
                    'C. Cảm xúc và cảm giác',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: 4 bước ra quyết định là gì?',
                answerchoice: [
                    'A. Suy nghĩ, cảm nhận, nói chuyện, hành động',
                    'B. Thất bại, cảm xúc tiêu cực, suy nghĩ, từ bỏ',
                    'C. Nói chuyện, suy nghĩ, hành động, cảm nhận',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 3,
        title: 'Bài 4: Tâm Trí',
        url: 'https://player.vimeo.com/video/1024650962',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Tên của 2 loại tâm trí là gì?',
                answerchoice: [
                    'A. Tâm trí siêu thức và tâm trí hiện trường',
                    'B. Tâm trí vô hình và hữu hình',
                    'C. Tâm trí ý thức và tâm trí vô thức',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Công việc hoặc mục đích của tâm trí ý thức là …',
                answerchoice: [
                    'A. Để đưa ra quyết định',
                    'B. Để nói đùa',
                    'C. Để làm cho mọi việc xảy ra',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Công việc hoặc mục đích của tâm trí vô thức là gì?',
                answerchoice: [
                    'A. Để làm bạn bối rối',
                    'B. Để mang lại cho bạn “Có và nhiều hơn” những gì bạn muốn',
                    'C. Nó không làm gì cả',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 4,
        title: 'Bài 5: Giao Tiếp',
        url: 'https://player.vimeo.com/video/1024651685',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Bài học lớn nhất mà bạn đã học được về giao tiếp là gì?',
                answerchoice: [
                    'A. Rằng giao tiếp rất quan trọng trong các mối quan hệ',
                    'B. Tốt khi biết điều đó',
                    "C. Những gì bạn có hoặc không có ngay bây giờ là vì 'bạn đã yêu cầu nó', và đó chính xác là những gì bạn xứng đáng",
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Công việc hoặc mục đích của tâm trí ý thức là …',
                answerchoice: [
                    'A. Xây dựng các mối quan hệ',
                    'B. Để được một câu trả lời “Có”',
                    'C. Chỉ để cho vui',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Đâu là “Sự điều hướng ngôn ngữ.”',
                answerchoice: [
                    'A. Giữ im lặng/Không nói về điều gì hết',
                    'B. Nói về những điều bạn không muốn',
                    'C. Nói về những điều bạn muốn',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 5,
        title: 'Bài 6: Sự Tưởng Tượng',
        url: 'https://player.vimeo.com/video/1025023871',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Sự tưởng tượng được thực hiện bởi bán cầu não phải hay trái?',
                answerchoice: [
                    'A. Bán cầu não trái',
                    'B. Bán cầu não phải',
                    'C. Cả hai bán cầu',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Chúng ta có bao nhiêu giác quan?',
                answerchoice: [
                    'A. 5 giác quan',
                    'B. 7 giác quan',
                    'C. 10 giác quan (5 bên ngoài và 5 bên trong)',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Trong thế giới, mọi thứ đều xảy ra hai lần. Đó là gì?',
                answerchoice: [
                    'A. Tưởng tượng trước và Hoàn thành điều đó',
                    'B. Nói và Làm',
                    'C. Bàn luận và Không đồng ý',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 6,
        title: 'Bài 7: Sử Dụng Sự Tưởng Tượng Để Giải Quyết Vấn Đề',
        url: 'https://player.vimeo.com/video/1025024096',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Bạn tự hỏi mình những câu hỏi nào khi gặp vấn đề?',
                answerchoice: [
                    'A. Vấn đề đó là gì?',
                    'B. Làm sao để biết được vấn đề của mình là gì?',
                    'C. Tại sao bạn lại có vấn đề gì?',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Câu hỏi bạn tự hỏi mình để tạo ra kết quả hoặc mục tiêu rõ ràng nhằm giải quyết vấn đề là gì?',
                answerchoice: [
                    'A. Tình huống đó là gì?',
                    'B. Ai sẽ là người giải quyết vấn đề này?',
                    'C. Làm sao để bạn biết được khi nào vấn đề được giải quyết?',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Mục đích của "Lý Do Lớn" để đạt được tất cả các mục tiêu của bạn là gì?',
                answerchoice: [
                    'A. Không quan trọng',
                    'B. Có cảm xúc giúp thúc đẩy việc đạt được mục tiêu',
                    'C. Để làm rõ',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 7,
        title: 'Bài 8: Mô Hình Mắt',
        url: 'https://player.vimeo.com/video/1025325539',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Mục đích của việc biết về Mô Hình Mắt là gì?',
                answerchoice: [
                    'A. Để trở nên dũng cảm và thành công',
                    'B. Có kỹ năng ghi nhớ và sáng tạo mạnh mẽ',
                    'C. Để phát hiện người khác nói dối',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Để nhớ màu sắc ngôi nhà của bạn khi bạn 6 tuổi, bạn sẽ di chuyển con ngươi của mình đến đâu?',
                answerchoice: [
                    'A. Mọi nơi',
                    'B. Lên phía trên bên phải',
                    'C. Lên phía trên bên trái',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Để tạo ra hình ảnh một con hươu cao cổ với đầu voi, bạn có di chuyển con ngươi của mình đến...',
                answerchoice: [
                    'A. Lên phía trên bên phải',
                    'B. Mọi nơi',
                    'C. Lên phía trên bên trái',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 8,
        title: 'Bài 9: Các Cấp Độ Trong Cuộc Sống',
        url: 'https://player.vimeo.com/video/1025325732',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Khi nào bạn có được sự lựa chọn trong cuộc đời?',
                answerchoice: [
                    'A. Trước khi sinh ra',
                    'B. Trong quá trình được sinh ra',
                    'C. Sau khi sinh',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Thông điệp chính trong bài học này là gì?',
                answerchoice: [
                    'A. Tôi có thể quyết định trở thành người thành công rực rỡ trong cuộc sống của mình',
                    'B. Không có lựa chọn',
                    'C. Thành công chỉ dành cho một số ít người',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Nếu muốn giúp mọi người thành công, bạn cần phải làm điều gì?',
                answerchoice: [
                    'A. Không làm gì cả',
                    'B. Đầu tiên, hãy trở thành người thành công nhất',
                    'C. Suy nghĩ, suy nghĩ và suy nghĩ',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 9,
        title: 'Bài 10: Kết Luận',
        url: 'https://player.vimeo.com/video/1025325842',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Không có điều gì là tồi tệ trong cuộc sống này cả',
                answerchoice: [
                    'A. Đúng',
                    'B. Có thể',
                    'C. Sai',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Khi nào điều xấu xảy ra với chúng ta?',
                answerchoice: [
                    'A. Khi chúng ta không đưa ra kết luận',
                    'B. Khi chúng ta không đưa ra quyết định',
                    'C. Khi chúng ta đưa ra kết luận sai lầm',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Điều gì sẽ xảy ra khi chúng ta có một kết luận Tốt hay Xấu?',
                answerchoice: [
                    'A. Không có gì xảy ra cả',
                    'B. Chúng ta sẽ có những Cảm Xúc về những Kết Luận',
                    'C. Tôi không biết',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 10,
        title: 'Trạm 1: Kỹ Năng',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Tại sao việc phát triển bản thân lại quan trọng?',
                answerchoice: [
                    'A. Cuộc sống vốn không công bằng và bất cứ ai quyết định thành công đều có thể thành công',
                    'B. Không quan trọng',
                    'C. Một số có thể, một số thì không',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2:  thức sâu sắc nhất của bạn sau bài học video là gì?',
                answerchoice: [
                    'A. Khi chúng ta không đưa ra kết luận',
                    'B. Khi chúng ta không đưa ra quyết định',
                    'C. Khi chúng ta đưa ra kết luận sai lầm',
                ],
                result: 2,
                ////
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Tại sao việc chia sẻ hoặc dạy những gì bạn đã học được cho người bạn yêu thương lại quan trọng?',
                answerchoice: [
                    'A. Để họ biết về những gì bạn đã học được',
                    'B. Để khoe khoang',
                    'C. Không có lí do',
                ],
                result: 0,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Điều gì là thứ bạn đã làm suốt cuộc đời mình?',
                answerchoice: [
                    'A. Học tập',
                    'B. Vui chơi',
                    'C. Ra quyết định',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 11,
        title: 'Trạm 2: Tư Duy',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Mọi sự tồn tại trên đời là kết quả của sự...của bạn',
                answerchoice: [
                    'A. Cống hiến',
                    'B. Kiên trì',
                    'C. Thông thái',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Bạn gặp thất bại vì điều gì?',
                answerchoice: [
                    'A. Không chịu ra quyết định',
                    'B. Kém may mắn',
                    'C. Không đủ giỏi',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Con người chúng ta, ai cũng được phép có quyền gì?',
                answerchoice: [
                    'A. Can đảm và Tự tin',
                    'B. Giàu có và Thông Minh',
                    'C. Hạnh Phúc và Giàu có',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 12,
        title: 'Trạm 3: Về Đích',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: NLP được phát minh khi nào?',
                answerchoice: [
                    'A. 1988',
                    'B. 1970',
                    'C. 2010',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Chức năng chính của não bộ chúng ta là gì?',
                answerchoice: [
                    'A. Thở và ngủ',
                    'B. Ghi nhớ và sáng tạo',
                    'C. Cảm xúc và cảm giác',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Tên của 2 loại tâm trí là gì?',
                answerchoice: [
                    'A. Tâm trí siêu thức và tâm trí hiện trường',
                    'B. Tâm trí vô hình và hữu hình',
                    'C. Tâm trí ý thức và tâm trí vô thức',
                ],
                result: 2,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Nhận thức sâu sắc nhất của bạn sau bài học video là gì?',
                answerchoice: [
                    'A. 1988',
                    'B. 1970',
                    'C. 2010',
                ],
                ////
                result: 1,
            },
            {
                id: 4,
                question: 'Câu hỏi 5:  Bài học lớn nhất mà bạn đã học được về giao tiếp là gì?',
                answerchoice: [
                    'A. Rằng giao tiếp rất quan trọng trong các mối quan hệ',
                    'B. Tốt khi biết điều đó',
                    'C. Những gì bạn có hoặc không có ngay bây giờ là vì "bạn đã yêu cầu nó", và đó chính xác là những gì bạn xứng đáng',
                ],
                result: 2,
            },
            {
                id: 5,
                question: 'Câu hỏi 6: Chúng ta có bao nhiêu giác quan?',
                answerchoice: [
                    'A. 5 giác quan',
                    'B. 7 giác quan',
                    'C. 10 giác quan (5 bên ngoài và 5 bên trong)',
                ],
                result: 2,
            },
            {
                id: 6,
                question: 'Câu hỏi 7: Câu hỏi bạn tự hỏi mình để tạo ra kết quả hoặc mục tiêu rõ ràng nhằm giải quyết vấn đề là gì?',
                answerchoice: [
                    'A. Tình huống đó là gì?',
                    'B. Ai sẽ là người giải quyết vấn đề này?',
                    'C. Làm sao để bạn biết được khi nào vấn đề được giải quyết?',
                ],
                result: 2,
            },
            {
                id: 7,
                question: 'Câu hỏi 8: Để tạo ra hình ảnh một con hươu cao cổ với đầu voi, bạn có di chuyển con ngươi của mình đến...',
                answerchoice: [
                    'A. Lên phía trên bên phải',
                    'B. Mọi nơi',
                    'C. Lên phía trên bên trái',
                ],
                result: 0,
            },
            {
                id: 8,
                question: 'Câu hỏi 9: Con người chúng ta, ai cũng được phép có quyền gì?',
                answerchoice: [
                    'A. Can đảm và Tự tin',
                    'B. Giàu có và Thông Minh',
                    'C. Hạnh Phúc và Giàu có',
                ],
                result: 1,
            },
            {
                id: 9,
                question: 'Câu hỏi 10: Khi nào điều xấu xảy ra với chúng ta?',
                answerchoice: [
                    'A. Khi chúng ta không đưa ra kết luận',
                    'B. Khi chúng ta không đưa ra quyết định',
                    'C. Khi chúng ta đưa ra kết luận sai lầm',
                ],
                result: 2,
            },
        ],
    },
];
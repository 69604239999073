import fansipangImage from "assets/images/course/course-online/fansipan.jpg";
import kinabaluImage from "assets/images/course/course-online/kinabalu.jpg";
import kiliImage from "assets/images/course/course-online/kili.jpg";
import aconcaguaImage from "assets/images/course/course-online/aconcagua.jpg";


export const colorsDataOverView = [
    { color: '#0000FF', text: 'Khóa miễn phí' },
    { color: '#FF0000', text: 'Khóa đang chọn' },
    { color: '#FFFF2E', text: 'Khóa theo gói' },
];

export const comboSaleDetailsOverView = [
    { comboName: 'GÓI TIÊU CHUẨN', colorPromo: '#FFFFFF', keyWord: [0], promoprice: 1999000, price: 999000, detail: ['Mở Khóa Tất Cả Video', 'Mở Khóa Tất Cả Các Trạm'] },
    { comboName: 'GÓI KỸ NĂNG', colorPromo: '#FFFFFF', keyWord: [1], promoprice: 890000, price: 499000, detail: ['Mở Khóa Video 2 3 4', 'Mở Khóa Trạm Kỹ Năng', 'Nhận MIỄN PHÍ vé GENERAL tham gia THE RESTART'] },
    { comboName: 'GÓI TƯ DUY', colorPromo: '#FFFFFF', keyWord: [2], promoprice: 890000, price: 599000, detail: ['Mở Khóa Video 5 6 7', 'Mở Khóa Trạm Tư Duy'], prerequisite: 'Phải hoàn thành tầng dưới' },
    { comboName: 'GÓI VỀ ĐÍCH', colorPromo: '#FFFFFF', keyWord: [3], promoprice: 890000, price: 599000, detail: ['Mở Khóa Video 8 9 10', 'Mở Khóa Trạm Về Đích'], prerequisite: 'Phải hoàn thành các tầng dưới' },
    { comboName: 'GÓI THÀNH VIÊN GENERAL', colorPromo: '#FF0000', keyWord: [0, 1, 2, 3], promoprice: 2499000, price: 890000, detail: ['Mở Khóa Tất Cả Video', 'Mở Khóa Tất Cả Các Trạm', 'Nhận MIỄN PHÍ vé GENERAL tham gia THE RESTART'], isBestSeller: true },
    { comboName: 'GÓI THÀNH VIÊN VIP', colorPromo: '#FFFFFF', keyWord: [0, 1, 2, 3], promoprice: 7999000, price: 4399000, detail: ['Mở Khóa Tất Cả Video', 'Mở Khóa Tất Cả Các Trạm', 'Nhận MIỄN PHÍ vé VIP tham gia THE RESTART'] },
];

export const pyramidItemsLayoutOverview = [
    [{ label: '10', color: '#808080' }],
    [{ label: '8', color: '#808080' }, { label: '9', color: '#808080' }],
    [{ label: '7', color: '#808080' }, { label: '6', color: '#808080' }, { label: '5', color: '#808080' }],
    [{ label: '1', color: '#0000FF' }, { label: '2', color: '#808080' }, { label: '3', color: '#808080' }, { label: '4', color: '#808080' }]
];
export const mountainOverview = [
    {
        mountainId: 1,
        mountainName: "Fansipan",
        mountainHeight: "3,143m",
        mountainColor: "#FFFF2E",
        mountainSummary: "Khóa học giúp học viên tiếp nhận nguồn kiến thức về NLP và cách áp dụng quy trình NLP vào cuộc sống hàng ngày",
        mountainTag: "FSP",
        mountainURL: fansipangImage,
        mountainPrequisite: "",
        mountainURLNavigate: "fansipan",
        mountainTime: "60 phút",
    },
    {
        mountainId: 2,
        mountainName: "Kinabalu",
        mountainHeight: "4,095m",
        mountainColor: "#0000FF",
        mountainSummary: "Mục tiêu của Khóa học chính là phương pháp kiểm soát cảm xúc, xác định bức tranh toàn cảnh của cuộc đời và hoạch định dự án cuộc đời",
        mountainTag: "KNBL",
        mountainURL: kinabaluImage,
        mountainPrequisite: "Phải hoàn thành Fansipang",
        mountainURLNavigate: "kinabalu",
        mountainTime: "70 phút",
    },
    {
        mountainId: 3,
        mountainName: "Kilimanjaro",
        mountainHeight: "5,895m",
        mountainColor: "#00FF00",
        mountainSummary: "Thấu hiểu về việc nâng tầm giá trị, tạo ra tầm nhìn trong cuộc sống. Đồng thời, hiểu rõ về quy luật vận hành của Bánh Xe Cuộc Đời và Bạn chọn phụng sự cho điều gì",
        mountainTag: "KLJ",
        mountainURL: kiliImage,
        mountainPrequisite: "Phải hoàn thành Kinabalu",
        mountainURLNavigate: "fansipan",
        mountainTime: "70 phút",
    },
    {
        mountainId: 4,
        mountainName: "Aconcagua",
        mountainHeight: "6,959m",
        mountainColor: "#FF0000",
        mountainSummary: 'Hành trình cuối cùng trong quá trình khám phá và hiểu rõ về Lòng biết ơn / Sự tha thứ / Mối quan hệ / Sức khỏe và Vùng mà bản thân bạn đang đứng. Vào khoảnh khắc kết thúc, bạn sẽ tìm ra được câu trả lời cho câu hỏi : "Tôi là ai?"',
        mountainTag: "ACCG",
        mountainURL: aconcaguaImage,
        mountainPrequisite: "Phải hoàn thành Kilimanjaro",
        mountainURLNavigate: "fansipan",
        mountainTime: "80 phút",
    },
]

export const mountainExplaination = {
    title: "Tổng Quan",
    explaination: "Mỗi ngọn núi sẽ đem lại những giá trị khác nhau",
    note: "Nhấp vào từng ngọn núi để xem thêm chi tiết",
    pressingRegister: "Hãy đăng nhập ngay để bắt đầu cuộc hành trình",
    buttonTitle: "Khám Phá Ngay",
}

export const howToDoMountain = {
    title: "Hướng Dẫn",
    explaination: "Cách hoạt động của mỗi ngọn núi như sau:",
    buttonTitle: "Khám Phá Giải Thưởng",

}

export const SCHEDULE_COURSE_ONLINE_GET = "SCHEDULE_COURSE_ONLINE_GET";
export const SCHEDULE_COURSE_ONLINE_CREATE = "SCHEDULE_COURSE_ONLINE_CREATE";
export const SCHEDULE_COURSE_ONLINE_UPDATE_IN_LIST =
  "UPDATE_SCHEDULE_COURSE_ONLINE_IN_LIST";
export const SCHEDULE_COURSE_ONLINE_LOADING = "SCHEDULE_COURSE_ONLINE_LOADING";
export const SCHEDULE_COURSE_ONLINE_LOADED = "SCHEDULE_COURSE_ONLINE_LOADED";
export const SCHEDULE_COURSE_ONLINE_HANDLE_USER =
  "SCHEDULE_COURSE_ONLINE_HANDLE_USER";
export const SCHEDULE_COURSE_ONLINE_HANDLE_USER_CHANGE_PERMISSION =
  "SCHEDULE_COURSE_ONLINE_HANDLE_USER_CHANGE_PERMISSION";
export const SCHEDULE_COURSE_ONLINE_HANDLE_ADD_USER_TO_SCHEDULE =
  "SCHEDULE_COURSE_ONLINE_HANDLE_ADD_USER_TO_SCHEDULE";
export const SCHEDULE_COURSE_ONLINE_HANDLE_DEL_USER_FROM_SCHEDULE =
  "SCHEDULE_COURSE_ONLINE_HANDLE_DEL_USER_FROM_SCHEDULE";
export const SCHEDULE_COURSE_ONLINE_HANDLE_USER_ANSWER_QUESTION =
  "SCHEDULE_COURSE_ONLINE_HANDLE_USER_ANSWER_QUESTION";

export const SCHEDULE_COURSE_ONLINE_DELETE = "SCHEDULE_COURSE_ONLINE_DELETE";
// FETCH ALL COURSE
export const FETCH_SCHEDULE_COURSE_ONLINE_REQUESTING =
  "FETCH_SCHEDULE_COURSE_ONLINE_REQUESTING";
export const FETCH_MORE_SCHEDULE_COURSE_ONLINE_SUCCESS =
  "FETCH_MORE_SCHEDULE_COURSE_ONLINE_SUCCESS";
export const FETCH_SCHEDULE_COURSE_ONLINE_SUCCESS =
  "FETCH_SCHEDULE_COURSE_ONLINE_SUCCESS";
export const FETCH_SCHEDULE_COURSE_ONLINE_FAILED =
  "FETCH_SCHEDULE_COURSE_ONLINE_FAILED";

export const FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_SUCCESS =
  "FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_SUCCESS";
export const FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_FAILED =
  "FETCH_DETAILS_SCHEDULE_COURSE_ONLINE_FAILED";

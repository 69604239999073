import { Button, Modal, Box } from "@mui/material";
import "./styles.scss";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import RegisterForm from "share/registerForm";
function FixedBottom({ ...props }) {
  const [openModal, setOpenModal] = useState(false);

  const renderPopUp = () => {
    const style = {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // borderRadius: "40px",
      boxShadow: 24,
      position: "absolute",
      display: "block",
      width: isMobile ? "90%" : "50%",
      maxHeight: "80%",
      overflow: isMobile ? "scroll" : "auto",
      "&:focus-visible": {
        outline: "0px solid #1976d2", // Add an outline for keyboard focus
        boxShadow: 12, // Example: different shadow on focus
      },
    };
    return (
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={
          {
            // borderRadius: "40px",
          }
        }
      >
        <Box sx={style}>
          <RegisterForm
            title={"Thông tin đăng ký !"}
            course={props.course}
            setOpenModal={(i) => {
              setOpenModal(i);
            }}
          />
        </Box>
      </Modal>
    );
  };

  return (
    <div className="tfs-fixed-bottom">
      <div className="container d-flex align-items-center justify-content-center h-100">
        <div className="img-register">
          <Button
            className=" tfs-btn-register animate-ring"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Đăng ký
          </Button>
        </div>
      </div>
      {renderPopUp()}
    </div>
  );
}

export default FixedBottom;

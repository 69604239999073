export const UTMTracking = (props) => {
  // Function to parse the query string into an object of key-value pairs
  const params = {};
  const queryStringWithoutQuestionMark = props.substring(1); // Remove the leading '?'
  const keyValuePairs = queryStringWithoutQuestionMark.split("&");
  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    params[key] = value;
  });
  // Parse the query string into an object
  // Access the values using the keys
  //   const utmSource = params["utm_source"];
  //   const utmMedium = params["utm_medium"];
  //   const utmCampaign = params["utm_campaign"];
  //   const utmTerm = params["utm_term"];
  //   const utmContent = params["utm_content"];
  localStorage.setItem("utm", JSON.stringify(params));
};

import React, { useEffect, useState } from "react";
import Bank from "assets/images/source/accountDetails/bank.png";
import Card from "assets/images/source/accountDetails/card.png";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { listLocalBank, listInterBank, payment } from "./const";
import SearchBar from "share/searchBar";
import { cloneDeep, isEmpty, upperCase } from "lodash";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";
import "./styles.scss";
import FormatNumberic from "share/formatNumber";
import { Button } from "@mui/material";

import {
  removeCourseOnlineFromCart,
  removeCourseFromCart,
  changeCountOfCourse,
  checkoutInfo,
} from "core/redux/actions/cartAction";
import { orderCreatePaymentUrl } from "core/redux/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "share/alertDialog";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "share/loading";
import InputPM from "../components/input";
import { useTranslation } from "react-i18next";

function OrderDetails(props) {
  // const [product, setProduct] = useState();
  const [error, setError] = useState({});
  const [readPolicy, setReadPolicy] = useState(false);
  const [method, setMethod] = useState("");
  const [txtSearch, setTxtSearch] = useState("");
  const [currentBank, setCurrentBank] = useState(listLocalBank);
  const [selectedBank, setSelectedBank] = useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [protocol, setProtocol] = useState("");
  const { t } = useTranslation("common");
  const { billCheck, totalPrice } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = true;

    if (ignore) {
      // do useEffect
      window.scrollTo(0, 0);
      !isEmpty(billCheck) &&
        billCheck.sort((a, b) =>
          a.type > b.type ? 1 : a.type < b.type ? -1 : 0
        );

      return () => {
        ignore = false;
      };
    }
  }, []);
  useEffect(() => {
    if (isEmpty(txtSearch)) {
      setCurrentBank(method === "bank" ? listLocalBank : listInterBank);
    } else {
      let newList = cloneDeep(
        method === "bank" ? listLocalBank : listInterBank
      );
      var query = txtSearch.toLowerCase();
      var b = newList.filter(
        (item) => item.title.toLowerCase().indexOf(query) >= 0
      );
      setCurrentBank(b);
    }
  }, [txtSearch]);

  const handleMethod = (type) => {
    setMethod(type);
    setTxtSearch("");
    setSelectedBank("");
    if (type === "bank" || type === "card") {
      setCurrentBank(type === "bank" ? listLocalBank : listInterBank);
    } else {
      setOpenAlertDialog(true);
    }
  };

  const handleRedirectPaymentURL = () => {
    setOpenAlertDialog(false);
    let vnp_BankCode =
      method === "bank" || method === "card"
        ? upperCase(selectedBank)
        : "VNPAYQR";
    let checkout = billCheck.filter((item) => item.checkout === true);

    dispatch(
      orderCreatePaymentUrl(
        vnp_BankCode,
        protocol,
        totalPrice.price,
        checkout,
        userInfo._id
      )
    );
  };

  const renderBank = (item, type) => {
    return (
      <div className="row render-bank">
        <div className="search-bank mb-3">
          <SearchBar
            placeholder="search_bank"
            txtSearch={txtSearch}
            handleDebouceSearch={(txt) => {
              setTxtSearch(txt);
            }}
          />
        </div>
        {item.length > 0 ? (
          item.map((ele, index) => {
            return (
              <div
                className="col-3 mb-3"
                key={index}
                onClick={() => {
                  setSelectedBank(ele.title);
                  setOpenAlertDialog(true);
                }}
              >
                <Slide cascade={true}>
                  <img
                    src={ele.img}
                    key={index}
                    className={`account-detail-bank px-3 ${
                      ele.title === selectedBank && "bank-selected"
                    }`}
                  />
                </Slide>
              </div>
            );
          })
        ) : (
          <div className="col-6 col-sm-6 col-md-3 mb-3 ">
            <div className="account-detail-payment hover-disable">
              {type === "bank" ? (
                <>
                  <img src={Bank} className="account-detail-payment-img px-4" />
                  <p className="text-center">Không Tìm Thấy Ngân Hàng</p>
                </>
              ) : (
                <>
                  <img src={Card} className="account-detail-payment-img px-4" />
                  <p className="text-center">Không Tìm Thấy Thẻ</p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPaymentMethod = () => {
    switch (method) {
      case "vnpay":
        return <></>;
      case "bank":
        return renderBank(currentBank, "bank");
      case "card":
        return renderBank(currentBank, "card");
      case "vivnpay":
        return <div></div>;
      default:
        return <div></div>;
    }
  };
  const renderPayment = () => {
    return (
      <Fade>
        <div className="row">
          <h4 className="mb-3">Chọn phương thức thanh toán</h4>
          {payment.map((item, index) => {
            return (
              <div
                className="col-6 col-sm-6 col-md-3 mb-3"
                key={index}
                onClick={() => {
                  handleMethod(item.type);
                }}
              >
                <div
                  className={`account-detail-payment ${
                    method === item.type ? "payment-selected" : null
                  }`}
                >
                  {
                    <img
                      src={item.img}
                      className="account-detail-payment-img px-4"
                    />
                  }
                  <p className="text-center">{item.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Fade>
    );
  };
  const renderMerchantInfo = () => {
    const handleSeletecedOrder = (product) => {
      dispatch(checkoutInfo(product));
    };
    const handleRemoveOrder = (item) => {
      item.type === "courseOnline"
        ? dispatch(removeCourseOnlineFromCart(item))
        : dispatch(removeCourseFromCart(item));
    };
    const handleCountOfCourse = (product, type) => {
      dispatch(changeCountOfCourse(product, type));
    };
    const renderCourseOnline = (product) => {
      return (
        <div className="p-3 d-flex align-items-center">
          <div
            onClick={() => {
              handleSeletecedOrder(product);
            }}
            className="pointer-cursor "
          >
            {product.checkout ? (
              <CheckBoxIcon fontSize="large" />
            ) : (
              <CheckBoxOutlineBlankIcon fontSize="large" />
            )}
          </div>
          <div
            className="col-3 pointer-cursor d-flex align-items-center justify-content-center"
            onClick={() => {
              handleSeletecedOrder(product);
            }}
          >
            <img src={product.img} alt="image-course" className="rounded" />
          </div>
          <div className="d-flex justify-content-between flex-fill">
            <div>
              <div>{product.course_name}</div>
              <div className="product-price">{product.pharse}</div>
              <div className="text-danger d-flex justify-content-between align-items-center">
                <FormatNumberic
                  num={product.price}
                  style={{ fontWeight: "bolder" }}
                />
              </div>
            </div>
            <div className="text-danger d-flex justify-content-between align-items-center">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleRemoveOrder(product);
                }}
              >
                Xoá
              </Button>
            </div>
          </div>
        </div>
      );
    };
    const renderCourseOffline = (product) => {
      return (
        <div className="p-3 d-flex align-items-center">
          <div
            onClick={() => {
              handleSeletecedOrder(product);
            }}
            className="pointer-cursor "
          >
            {product.checkout ? (
              <CheckBoxIcon fontSize="large" />
            ) : (
              <CheckBoxOutlineBlankIcon fontSize="large" />
            )}
          </div>
          <div
            className="col-3 pointer-cursor d-flex align-items-center justify-content-center"
            onClick={() => {
              handleSeletecedOrder(product);
            }}
          >
            <img src={product.img} alt="image-course" className="rounded" />
          </div>
          <div className=" d-flex justify-content-between flex-fill">
            <div>
              <div>{t(`${product.course_name}`)}</div>
              {product.ticket && (
                <div className="product-price">Loại vé: {product.ticket}</div>
              )}
              <div
                className="d-flex text-danger align-items-center"
                style={{ fontWeight: "bolder" }}
              >
                <FormatNumberic num={product.price} />

                <div className="product-count ml-3">
                  <RemoveIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCountOfCourse(product, "remove");
                    }}
                  />
                  <div className="break-line "></div>
                  <div className="px-2">{product.count}</div>
                  <div className="break-line"></div>
                  <AddIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCountOfCourse(product, "add");
                    }}
                  />
                </div>
              </div>
              <div className="product-user">email: {product.user.email}</div>
            </div>

            <div className="text-danger d-flex justify-content-between align-items-center">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleRemoveOrder(product);
                }}
              >
                Xoá
              </Button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className=" mb-5">
        <h4 className="mb-3">Thông tin đơn hàng</h4>
        <div className="account-detail-information ">
          {billCheck.map((product, index) => {
            console.log("product:", product);
            return (
              <div className="detail-product row " key={index}>
                {product.type === "courseOnline" && renderCourseOnline(product)}
                {product.type === "courseOffline" &&
                  renderCourseOffline(product)}
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12 font-style">
            <InputPM
              type="checkbox"
              label="accept_payment-LPE"
              name="acceptPolicy"
              wrapperClassProps="col-12"
              checkboxText="Tôi chấp nhận thanh toán và đã đọc phần "
              onChange={(e) => {
                setReadPolicy(e.target.checked);
              }}
              error={error.acceptPolicy}
              urlTitle="điều khoản của The Origins"
              url="/chinh-sach-thanh-toan-online"
            />
          </div>
        </div>

        <div className="account-total-information">
          {totalPrice?.price && totalPrice?.price !== 0 && readPolicy ? (
            <>
              Tổng thanh toán: &nbsp;
              <FormatNumberic num={totalPrice?.price} />
            </>
          ) : (
            "Vui lòng chọn đơn hàng & xác nhận đã đọc điều khoản"
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      {billCheck && billCheck?.length > 0 ? (
        <div className="account-detail-order mt-3">
          {renderMerchantInfo()}

          {totalPrice?.price && readPolicy ? (
            <>
              {renderPayment()}
              {renderPaymentMethod()}
            </>
          ) : (
            <></>
          )}
          <AlertDialog
            handleAction={() => {
              handleRedirectPaymentURL();
            }}
            openAlertDialog={openAlertDialog}
            setOpenAlertDialog={setOpenAlertDialog}
            title={"Xác nhận phương thức thanh toán"}
          />
        </div>
      ) : (
        <div className="no-data py-5">
          <hr />
          <h3>Bạn chưa có đơn hàng nào</h3>
        </div>
      )}
    </div>
  );
}

export default OrderDetails;

import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";

import { useTranslation } from "react-i18next";
import "swiper/components/pagination/pagination.min.css";
import "./styles/styles.scss";
import { Fade } from "react-awesome-reveal";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

function Timeline(props) {
  const { t } = useTranslation("common");
  const refPagination = useRef();

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      const ref = refPagination.current;

      let year = ref
        .querySelectorAll(".swiper-slide")
      [index].getAttribute("data-year");

      return '<span class="' + className + '">' + year + "</span>";
    },
  };

  return (
    <div className="wrapper-timeline rounded ">
      <div className="timeline rounded ">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          className="swiper-container rounded"
          direction={"vertical"}
          pagination={pagination}
          navigation={true}
          speed={1500}
          ref={refPagination}
          allowTouchMove={false}
          noSwiping
        >
          <SwiperSlide
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=13)",
            }}
            className="swiper-slide rounded"
            data-year="Achievement"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">{t("achievement")}</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <Fade left cascade>
                <div className="timeline-text">
                  <ol>
                    {Array.from({ length: 8 }, (_, index) => (
                      <li key={index}>{t(`tr_timeline_${index + 1}`)}</li>
                    ))}
                  </ol>
                </div>
              </Fade>
            </div>
          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=13)",
            }}
            className="swiper-slide rounded"
            data-year="2003"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">2003</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <p className="timeline-text">
                {t("tr_timeline_inner_1")}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="swiper-slide rounded"
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=14)",
            }}
            data-year="2004"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">2004</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <p className="timeline-text">
                {t("tr_timeline_inner_2")}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="swiper-slide"
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=15)",
            }}
            data-year="2019"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">2019</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <p className="timeline-text">
                {t("tr_timeline_inner_3")}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="swiper-slide rounded"
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=15)",
            }}
            data-year="2020"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">2020</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <p className="timeline-text">
                {t("tr_timeline_inner_4")}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="swiper-slide rounded"
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=15)",
            }}
            data-year="2021"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">2021</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <p className="timeline-text">
                {t("tr_timeline_inner_5")}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="swiper-slide rounded"
            style={{
              backgroundImage:
                props?.view === "tfs"
                  ? "unset"
                  : "url(https://unsplash.it/1920/500?image=15)",
            }}
            data-year="2022"
          >
            <div className="swiper-slide-content">
              <span className="timeline-year">2022</span>

              {/* <h4 className="timeline-title">Our nice super title</h4> */}
              <p className="timeline-text">
                {t("tr_timeline_inner_6")}
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Timeline;

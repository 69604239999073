import React, { useEffect, useState } from "react";

import "./styles.scss";
import { isEmpty } from "lodash";
import Loading from "share/loading";
import { RenderCourseOffline, RenderCourseOnline } from "./renderCourse";
import ZaloImg from "assets/images/ZALO.png";
import FBImg from "assets/images/facebook.jpg";

function SuccessTransaction(props) {
  const [data, setData] = useState("");
  useEffect(() => {
    setData(props.order);
  }, [props.order]);
  const handleFbClick = () => {
    window.open("https://www.facebook.com/theoriginsacademy", "_blank");
  };
  const handleZaloClick = () => {
    window.open("https://zalo.me/g/bqufiq446", "_blank");
  };
  return isEmpty(data) ? (
    <Loading />
  ) : (
    <div className="wrapper-content container mb-5 mt-5">
      <div className="product">
        <div className="d-flex justify-content-center">
          <i className="fal fa-check"></i>
        </div>
        <div className="content-body_product">
          <h3 className="mt-4">thanh toán thành công</h3>
        </div>
      </div>
      <div className="row mt-5">
        <div className="detail-info col-8 offset-2">
          <h3 className="d-flex justify-content-center">
            Thông tin thanh toán
          </h3>
          {data?.product?.map((ele, index) => {
            return ele.type === "courseOffline" ? (
              <RenderCourseOffline
                product={ele}
                vnpayReturn={true}
                key={index}
              />
            ) : ele.type === "courseOnline" ? (
              <RenderCourseOnline
                product={ele}
                vnpayReturn={true}
                key={index}
              />
            ) : (
              <div key={index}></div>
            );
          })}
        </div>
      </div>
      <div className="container d-flex flex-column align-items-center mt-5">
        <div className="col-9 d-flex justify-content-center">
          <div className="row d-flex w-100" style={{ backgroundColor: "blue", alignItems: "center", height: "40px" }}>
            <p className="m-0 text-center" style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white", flex: 1 }}>
              Hãy cùng tham gia nhóm cùng Vasanth Gopalan
            </p>
          </div>
        </div>
        <div className="container mt-10 d-flex ">
          <div className="col-6 d-flex w-100 px-20">
            <div
              className="card d-flex w-100"
              style={{
                width: "18rem",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <img
                src={FBImg}
                className="card-img-top"
                alt="Card cap"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="card-body" style={{ padding: "1rem" }}>
                <h5 className="card-title">
                  Tham gia cộng đồng để cập nhật thông tin giá trị từ Chuyên gia
                </h5>
                <p className="card-text" style={{ color: "grey" }}>
                  Nhóm Riêng Tư: 153.493 thành viên
                </p>
                <button
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                  onClick={handleFbClick}
                >
                  Tham Gia Nhóm
                </button>
              </div>
            </div>
          </div>

          <div className="col-6 d-flex w-100 px-20 text-right">
            <div
              className="card d-flex w-100"
              style={{
                width: "18rem",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <img
                src={ZaloImg}
                className="card-img-top"
                alt="Card cap"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="card-body" style={{ padding: "1rem" }}>
                <h5 className="card-title">
                  Tham gia nhóm zalo để đăng ký và cập nhật thông tin
                </h5>
                <p className="card-text" style={{ color: "grey" }}>
                  Nhóm Riêng Tư: 153.493 thành viên
                </p>
                <button
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                  onClick={handleZaloClick}
                >
                  Tham Gia Nhóm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessTransaction;

import React from "react";
import TableCell from "@mui/material/TableCell";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import { Button } from "@mui/material";
import { TableRow } from "@material-ui/core";
import { convertFullDate, unixToTime } from "core/utils/dateUtil";
import { useTranslation } from "react-i18next";
import FormatNumberic from "share/formatNumber";

function TablePaymentProductList({
  dataItem,
  labelId,
  handleOpenDrawer,
  onHandleDelete,
}) {
  const { t } = useTranslation("common");

  return (
    <TableRow>
      <TableCell align="left">{dataItem.vnp_CreateDate}</TableCell>
      <TableCell align="left">{dataItem?.user[0]?.name}</TableCell>
      <TableCell align="left">{dataItem?.user[0]?.phone}</TableCell>
      {/* <TableCell align="left">{t(dataItem?.course_name)}</TableCell> */}
      <TableCell align="left">
        {dataItem.status === 0
          ? "pending"
          : dataItem.status === 1
          ? "success"
          : "fail"}
      </TableCell>
      <TableCell align="left">{dataItem.vnp_BankCode}</TableCell>
      <TableCell align="left">
        <FormatNumberic num={dataItem.vnp_Amount / 100} />
      </TableCell>
    </TableRow>
  );
}

export default TablePaymentProductList;

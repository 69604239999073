import React, { useEffect, useState } from "react";
import "./styles.scss";
import SearchBar from "share/searchBar";
import DatePickerField from "app/components/customField/datePickerField";
import { FastField, Formik } from "formik";
import { Box, Button, styled } from "@mui/material";
import { Form, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  getOrderList,
  orderQuerydrVnpay,
} from "core/redux/actions/orderAction";

import "./styles.scss";
import Loading from "share/loading";
import AdminTable from "share/adminTable";

function PaymentManager() {
  const [txtSearch, setTxtSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { orderLoading, orderDetail, orderList } = useSelector(
    (state) => state.order
  );
  const dispatch = useDispatch();
  console.log("orderList", orderList);
  const headCells = [
    { id: "vnpCreateDate", label: "Ngày Tạo" },
    { id: "userName", label: "Tên khách hàng" },
    { id: "phone", label: "SĐT" },
    { id: "orderStatus", label: "Tình Trạng Đơn Hàng" },
    { id: "vnpBankCode", label: "Loại Thanh Toán" },
    { id: "vnpAmount", label: "Số Tiền" },
    { id: "action", label: "Xem Chi Tiết" },
  ];
  useEffect(() => {
    if (!isEmpty(txtSearch)) handleLoadData(txtSearch);
  }, [txtSearch]);

  useEffect(() => {
    handleLoadData();
  }, []);

  const handleLoadData = (txt) => {
    // handle limit & page dot
    dispatch(getOrderList(txt));
  };

  const renderBody = () => {
    return (
      <div className="payment-manager-body">
        <div className="d-flex align-items-center mb-5">
          <SearchBar
            placeholder="Mã Đơn Hàng"
            className=""
            label="Tìm Kiếm Thanh Toán"
            handleDebouceSearch={(txt) => setTxtSearch(txt)}
          />
          <button className="btn btn-primary ml-5" onClick={() => {}}>
            Tìm
          </button>
        </div>
        {orderList && orderList.length > 0 && (
          <AdminTable
            tableHead={headCells}
            tableData={orderList}
            view="payment_manager"
          />
        )}
      </div>
    );
  };
  return (
    <div className="payment-manager">
      {orderLoading ? <Loading /> : renderBody()}
    </div>
  );
}

export default PaymentManager;

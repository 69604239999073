export const statusMessage = [
  {
    code: "00",
    message: "Giao dịch thành công",
  },
  {
    code: "01",
    message: "Giao dịch không được tìm thấy",
  },
  {
    code: "02",
    message: "Giao dịch đã hoàn thành trước đó",
  },
  {
    code: "07",
    message:
      "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường)",
  },
  {
    code: "09",
    message:
      "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng",
  },
  {
    code: "10",
    message:
      "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần",
  },
  {
    code: "11",
    message:
      "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
  },
  {
    code: "12",
    message:
      "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
  },
  {
    code: "13",
    message:
      "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
  },
  {
    code: "24",
    message: "Giao dịch không thành công do: Khách hàng hủy giao dịch",
  },
  {
    code: "51",
    message:
      "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
  },
  {
    code: "65",
    message:
      "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
  },
  {
    code: "75",
    message: "Ngân hàng thanh toán đang bảo trì.",
  },
  {
    code: "79",
    message:
      "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
  },
  {
    code: "97",
    message: "Có lỗi thay đổi dữ liệu khi truyền tải",
  },
  {
    code: "99",
    message:
      "Các lỗi khác (lỗi còn lại, không có trong danh sách mã lỗi đã liệt kê)",
  },
];

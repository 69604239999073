import {
  FETCH_CART,
  ADD_PRODUCT,
  FETCH_LOCAL_BILL,
  REMOVE_COURSE,
  ADD_COURSE_TO_CART,
  ADD_COURSE_ONLINE_TO_CART,
  CHANGE_COUNT_OF_COURSE,
  REMOVE_COURSE_FROM_CART,
  REMOVE_COURSE_ONLINE_FROM_CART,
  CHECKOUT_INFO,
} from "core/redux/constant/cartConstants";
import { isEmpty } from "lodash";

// Khởi tạo State
const initialState = {
  billCheck: [],
  totalPrice: { count: 0, price: 0 },
  statusProductAddingCart: false,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART: {
      const bill = JSON.parse(localStorage.getItem("billCheckout"));
      let totalPrice;
      console.log("bill", bill);
      if (!isEmpty(bill) && bill?.length > 0) {
        totalPrice = bill.reduce(
          (acc, item, index) => {
            if (item.checkout === true) {
              acc.price += item.price * item.count;
              acc.count += item.count;
            }
            return acc;
          },
          { price: 0, count: 0 } // Initial value as an object
        );
      }
      // const totalPrice = localStorage.getItem("billTotalPrice");
      var hours = 1; // to clear the localStorage after 1 hour
      // (if someone want to clear after 8hrs simply change hours=8)
      var now = new Date().getTime();
      var setupTime = localStorage.getItem("setupTime");
      if (setupTime == null) {
        localStorage.setItem("setupTime", now);
      } else {
        if (now - setupTime > hours * 7 * 60 * 60 * 1000) {
          localStorage.clear();
          localStorage.setItem("setupTime", now);
        }
      }
      if (isEmpty(bill)) {
        state.billCheck = [];
        state.totalPrice = { price: 0, count: 0 };
      } else {
        state.billCheck = bill;
        state.totalPrice = totalPrice;
      }
      return { ...state };
    }
    case ADD_PRODUCT: {
      const billList = [...state.billCheck];
      const index = billList.findIndex((y) => y === action.payload);

      if (index === -1) {
        billList.push(action.payload);
        state.statusProductAddingCart = true;
      } else {
        state.statusProductAddingCart = false;
      }

      const prodLocal = JSON.stringify(billList);
      localStorage.setItem("billCheckout", prodLocal);

      state.billCheck = billList;
      return { ...state };
    }
    case ADD_COURSE_TO_CART: {
      return { ...state, billCheck: action.payload };
    }
    case REMOVE_COURSE_FROM_CART:
      return {
        ...state,
        billCheck: action.payload, // Update the billCheck array with the new list
      };
    case CHANGE_COUNT_OF_COURSE: {
      state.totalPrice = action.payload.total;
      state.billCheck = action.payload.billList;
      return { ...state };
    }
    case ADD_COURSE_ONLINE_TO_CART: {
      return { ...state, billCheck: action.payload };
    }

    case REMOVE_COURSE_ONLINE_FROM_CART: {
      return {
        ...state,
        billCheck: action.payload, // Update the billCheck array with the new list
      };
    }

    case FETCH_LOCAL_BILL: {
      state.billCheck = action.payload;

      return { ...state };
    }

    case REMOVE_COURSE: {
      let result = [...state.billCheck];
      const index = result.findIndex((y) => y === action.payload);

      if (index !== -1) {
        result.splice(index, 1);
      }

      const prodLocal = JSON.stringify(result);
      localStorage.setItem("billCheckout", prodLocal);

      state.billCheck = result;

      return { ...state };
    }

    case CHECKOUT_INFO: {
      state.billCheck = action.payload.billList;
      state.totalPrice = action.payload.total;
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

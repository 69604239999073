import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

function IPN() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   let ignore = true;
  //   if (ignore) {
  //     // do useEffect
  //     if (location.search) {
  //       const params = {};
  //       const queryStringWithoutQuestionMark = location.search.substring(1); // Remove the leading '?'
  //       const keyValuePairs = queryStringWithoutQuestionMark.split("&");
  //       keyValuePairs.forEach((pair) => {
  //         const [key, value] = pair.split("=");
  //         params[key] = value;
  //       });
  //       dispatch(orderVnpayIpn(params, setStatus));
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   return () => {
  //     ignore = false;
  //   };
  // }, []);
  return <div>IPN</div>;
}

export default IPN;

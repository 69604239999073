export const GET_IP_LOCAL = "GET_IP_LOCAL";
export const GET_USER = "GET_USER";
export const UPDATE_USER_IN_LIST = "UPDATE_USER_IN_LIST";
export const ADD_USER = "ADD_USER";

// FETCH ALL USER
export const FETCH_ALL_USER_REQUESTING = "FETCH_ALL_USER_REQUESTING";
export const FETCH_ALL_USER_SUCCESS = "FETCH_ALL_USER_SUCCESS";
export const FETCH_ALL_USER_FAILED = "FETCH_ALL_USER_FAILED";

// FETCH TOP TEN USER
export const FETCH_TOP_TEN_USER = "FETCH_TOP_TEN_USER";
export const FETCH_TOP_TEN_USER_SUCCESS = "FETCH_TOP_TEN_USER_SUCCESS";
export const FETCH_TOP_TEN_USER_FAILED = "FETCH_TOP_TEN_USER_FAILED";
import React from "react";
// import { moment } from "moment-timezone";

function FormatDate(props) {
  // const { date } = props;
  // const dateString = date.getYear();
  return <div>dateString</div>;
}

export default FormatDate;

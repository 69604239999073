export const STUDENT_GET = "STUDENT_GET";
export const STUDENT_CREATE = "STUDENT_CREATE";
export const STUDENT_UPDATE_IN_LIST = "UPDATE_STUDENT_IN_LIST";
export const STUDENT_LOADING = "STUDENT_LOADING";
export const STUDENT_LOADED = "STUDENT_LOADED";
export const STUDENT_DELETE = "STUDENT_DELETE";
// FETCH ALL STUDENT
export const FETCH_STUDENT_REQUESTING = "FETCH_STUDENT_REQUESTING";
export const FETCH_MORE_STUDENT_SUCCESS = "FETCH_MORE_STUDENT_SUCCESS";
export const FETCH_STUDENT_SUCCESS = "FETCH_STUDENT_SUCCESS";
export const FETCH_STUDENT_FAILED = "FETCH_STUDENT_FAILED";

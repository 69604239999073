import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useSiteTitle from "core/hooks/useSiteTitle";

import { API_ENDPOINT, VERIFY_REGISTER } from "app/const/Api";

import "./styles/styles.scss";
import { useSelector } from "react-redux";

function ConfirmRegister() {
  useSiteTitle("confirm_register");
  let { token } = useParams();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  // http://localhost:3000/xac-nhan-dang-ky/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiaXJ0aERheSI6MTY5NjI2NjAwMDAwMCwiZW1haWwiOiJ0dGtpZW45OTRAZ21haWwuY29tIiwiZ2VuZGVyIjoibmFtIiwibmFtZSI6InRyaW5oIHRydW5nIGtpZW4iLCJwYXNzd29yZCI6IiQyYSQxMCRwb1hVWTVqNElPb1gzTnFXa0FrYVVPSEtUZ1BuMS45N0F6MVQvM1djVFlhWEEzSjZUWndnZSIsInBob25lIjoiMDM4MzIwNDM2NyIsImlhdCI6MTY5NjQwMzI1MiwiZXhwIjoxNjk2NDAzNTUyfQ.OdyI_MfuOEW-_2oPK2GxrW4ODJR-gbYL5JGgNJOant4

  useEffect(() => {
    if (userInfo && userInfo._id) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    setIsLoading(true);

    axios({
      method: "post",
      url: API_ENDPOINT + VERIFY_REGISTER,
      data: {
        token: token,
      },
    })
      .then((res) => {
        console.log("res", res);
        setError("");
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code outside the 2xx range
          setError(error.response.data);
          if (error.response.status === 401) {
            setError("Đã hết hạn xác thực");
          } else if (error.response.status === 403) {
            setError("Sai thông tin xác thực");
          } else {
            setError("Lỗi không xác định được");
            console.log("Other error:", error.response.status);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log("No response received:", error.request);
          setError("Không có phản hồi từ máy chủ");
        } else {
          // Something else happened while setting up the request
          setError("Lỗi không xác định được");
          // console.log("Error setting up the request:", error.message);
        }
        setIsLoading(false);
      });
  }, [token]);

  return (
    <>
      <main className="forgetContainer">
        {isLoading ? (
          "Đang tải"
        ) : (
          <div className="formContainerForget">
            <div className="formHeading px-3">
              <h4>Welcome to your THE ORIGINS FAMILY</h4>
            </div>

            {error ? (
              <h4 className="mt-3">{error}</h4>
            ) : (
              <>
                <p className="descText px-4">
                  Tài khoản của bạn đã được xác thực
                </p>

                <p className="descText px-4">
                  Giờ đây bạn có thể đăng nhập và sử dụng tài khoản.
                </p>

                <div className="formInput">
                  <Link
                    to="/dang-nhap"
                    className="confirmBtn my-3 btn btn-primary"
                  >
                    Đăng nhập
                  </Link>
                </div>
              </>
            )}
          </div>
        )}
      </main>
    </>
  );
}

export default ConfirmRegister;

import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import policy from "assets/images/policy-payment.pdf";

function PaymentPolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isMobile ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <p>PDFs might not be viewable on mobile. Please download it below:</p>
          <a href={policy} download>
            Download PDF
          </a>
        </div>
      ) : (
        <object
          data={policy}
          type="application/pdf"
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <p>
            Alternative text - include a link <a href={policy}>to the PDF!</a>
          </p>
        </object>
      )}
    </div>
  );
}

export default PaymentPolicy;
